import Utils from "./index";

export default class UtilsTimeTrack {

    static updateLiveEdit(element: HTMLElement, data: any) {
        const parent = ((element.closest("ul") as HTMLUListElement).closest("div") as HTMLElement);
        (document.querySelector("#time_entry_committed_messages .backend_errors") as HTMLElement).innerHTML = '';
        if (data.failed) {
            let failedHtml = '';
            for(const message of data.messages) {
                failedHtml += `<div class="alert alert-danger" role="alert">${message}</div>`
            }
            (document.querySelector("#time_entry_committed_messages .backend_errors") as HTMLElement).innerHTML += failedHtml;

            ((parent.closest("form") as HTMLFormElement).querySelectorAll("button[data-mode]") as NodeListOf<HTMLButtonElement>).forEach((button: HTMLButtonElement) => {
                button.setAttribute("disabled", "disabled")
            })
        } else {
            element.querySelectorAll("li").forEach((li, i: number) => {
                const calc_time = li.querySelector("p.text-muted.text-nowrap span") as HTMLElement
                if (data.config[i].time) {
                    calc_time.innerHTML = data.config[i].time
                }
            })
            const overview = parent.querySelector(".timeline-overview") as HTMLElement;
            overview.innerHTML = UtilsTimeTrack.generateOverview(data.working, data.planned, data.pause, data.absences);
            ((parent.closest("form") as HTMLFormElement).querySelectorAll("button[data-mode]") as NodeListOf<HTMLButtonElement>).forEach((button: HTMLButtonElement) => {
                button.removeAttribute("disabled")
            })
        }
        ((parent.closest("form") as HTMLFormElement).querySelector("input[data-entity-field='config']") as HTMLInputElement).value = JSON.stringify(data.config)
    }

    static liveEdit(element: HTMLElement, date: Date) {
        element.querySelectorAll("li").forEach((li) => {
            const elem = (li.querySelector("h6.mb-0") as HTMLElement)
            const time = elem.innerHTML
            elem.innerHTML = `<div class="row"><div class="col-8"><input type="time" class="form-control" value='${time}' /></div><div class="col-4"><button class="btn btn-danger"><i class="ti ti-xs ti-x"></i></button> </div> </div> `;
            (elem.querySelector("input") as HTMLInputElement).addEventListener("keyup", (e: any) => {
                Utils.timeTrack.buildConfig(element, date);
            });
            (elem.querySelector("button") as HTMLInputElement).addEventListener("click", (e: any) => {
                e.preventDefault();
                const target = e.target as HTMLButtonElement;
                (target.closest("li") as HTMLLIElement).remove();
                Utils.timeTrack.buildConfig(element, date);
            })
        })
    }

    static async buildConfig(element: HTMLElement, date: Date) {
        let config: { at: string; mode: string | null; }[] = []
        element.querySelectorAll("li").forEach((li) => {
            const elem = (li.querySelector("h6.mb-0") as HTMLElement)
            const mode = (li.querySelector(".timeline-header") as HTMLElement).getAttribute("data-mode")
            let value = elem.innerHTML;
            if (elem.querySelector("input")) {
                value = (elem.querySelector("input") as HTMLInputElement).value
            }
            config.push({at: value, mode})
        });
        const calculated = await Utils.entity.request('/api/v1/time_entries/calculate', 'POST', {
            date: date,
            config: config,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
        Utils.timeTrack.updateLiveEdit(element, calculated)
    }

    static generateOverview(full_working: string, full_planned: string, full_pause: string, absences: any) {
        let diffPlanWorking = parseInt(full_working) - parseInt(full_planned)
        let chevron = 'ti-minus'
        if (diffPlanWorking > 0) {
            chevron = 'ti-chevron-up text-success'
        } else if (diffPlanWorking < 0) {
            chevron = 'ti-chevron-down text-danger'
        }
        let html = `<li class="d-flex mb-3">
                <div class="avatar flex-shrink-0 me-3">
                  <span class="avatar-initial rounded bg-label-success"><i class="ti ti-player-play-filled ti-sm"></i></span>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                  <div class="me-2">
                    <h6 class="mb-0">${Utils.translate("timetrack.work_time")}</h6>
                    <small class="text-muted">${Utils.secondsToHhMm(parseInt(full_working || ""))} / ${Utils.secondsToHhMm(parseInt(full_planned || ""))}</small>
                  </div>
                  <div class="user-progress d-flex align-items-center gap-3">
                    <div class="d-flex align-items-center gap-1">
                      <i class="ti ${chevron}"></i>
                      <small class="text-muted">${Utils.secondsToHhMm(diffPlanWorking || 0)}</small>
                    </div>
                  </div>
                </div>
              </li>
              <li class="d-flex mb-3">
                <div class="avatar flex-shrink-0 me-3">
                  <span class="avatar-initial rounded bg-label-warning"><i class="ti ti-player-pause-filled ti-sm"></i></span>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                  <div class="me-2">
                    <h6 class="mb-0">${Utils.translate("timetrack.pause_time")}</h6>
                    <small class="text-muted">${Utils.secondsToHhMm(parseInt(full_pause || ""))}</small>
                  </div>
                </div>
              </li>`;
        if (absences && absences.length > 0) {
            absences.forEach((absence: any) => {
                console.log("absence", absence)
                html += `<li class="d-flex mb-3">
                <div class="avatar flex-shrink-0 me-3">
                  <span class="avatar-initial rounded bg-label-primary"><i class="ti ti-door-exit ti-sm"></i></span>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                  <div class="me-2">
                    <h6 class="mb-0">${absence["name"]}</h6>
                  </div>
                  <div class="user-progress d-flex align-items-center gap-3">
                    <div class="d-flex align-items-center gap-1">
                      <i class="ti ${absence["added_time"] > 0 ? 'ti-chevron-up text-success' : 'ti-chevron-down text-danger'}"></i>
                      <small class="text-muted">${Utils.secondsToHhMm(absence["added_time"])}</small>
                    </div>
                  </div>
                </div>
              </li>`
            })
        }
        return html;
    }

    static generateList(data: any) {
        let ulHtml = '';
        data.forEach((d: any, i: number) => {
            let html = ` <li class="timeline-item ps-4 ${data[i+1] && d.mode !== "OUT" ? 'border-left-dashed' : 'border-transparent'}">`;
            let bsClass = ''
            let bsIcon = ''
            let title = ''
            let timestamp = new Date(Date.parse(d.at)).toLocaleTimeString()
            let type = ''
            if (d.mode === "IN") {
                bsClass = 'success';
                bsIcon = 'ti-player-play-filled'
                title = Utils.translate("timetrack.states.start")
            } else if(d.mode === "OUT") {
                bsClass = 'danger';
                bsIcon = 'ti-player-stop-filled'
                title = Utils.translate("timetrack.states.end")
            } else if(d.mode === "P_IN") {
                bsClass = 'warning';
                bsIcon = 'ti-player-pause-filled'
                title = Utils.translate("timetrack.states.pause")
            } else if(d.mode === "P_OUT") {
                bsClass = 'success';
                bsIcon = 'ti-player-play-filled'
                title = Utils.translate("timetrack.states.pause_end")
            }

            if (d.type === 'work_time') {
                type = "Arbeitszeit"
            }else if (d.type === "pause") {
                type = "Pausenzeit"
            }

            html += `<span class="timeline-indicator timeline-indicator-${bsClass}">
                <i class="ti ${bsIcon}"></i>
              </span>
            <div class="timeline-event ps-0 pb-0">
              <div class="timeline-header" data-mode="${d.mode}">
                <small class="text-${bsClass} text-uppercase fw-medium">${title} ${d.generated ? "<span class='text-muted' style='font-size: 8px;'>Generiert <!--<i data-bs-toggle='tooltip' data-bs-placement='top' title='Dieser Zeitstempel wurde generiert um gesetzliche bestimmungen zu erfüllen.' class='ti ti-help ti-xs'> </i>!--></span>" : ""}</small>
              </div>
              <h6 class="mb-0">${timestamp}</h6>
              <p class="text-muted mb-0 text-nowrap">
                ${d.time ? `+<span>${d.time}</span> ${type}` : ""}
              </p>

            </div>`
            html += '</li>'
            ulHtml += html;
        })
        return ulHtml
    }
}